import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Box, Button, Heading, FormField, Text, TextInput } from 'grommet'
import { Alert } from 'grommet-icons'

import { changeEmail, changePassword, signin, navigateResetPassword } from '../actions/user'
import AppLayout from '../components/app-layout'

const mapStateToProps = (state, props) => ({
  email: state.signup.email,
  password: state.signup.password,
  ready: (
    state.signup.email &&
    state.signup.password
  ),
  loading: state.signup.loading,
  error: state.signup.error,
  location: props.location.search
})

const mapDispatchToProps = dispatch => ({
  onEmailChange: val => dispatch(changeEmail(val)),
  onPasswordChange: val => dispatch(changePassword(val)),
  onSignin: (email, password, page, id) => dispatch(signin(email, password, page, id)),
  onPasswordReset: () => dispatch(navigateResetPassword())
})

const getParam = (param, location) => {
  const url = new URL(`https://www.ginihealth.com/${location}`)
  return url.searchParams.get(param)
}

const SigninPage = ({
  email,
  password,
  ready,
  loading,
  error,
  onEmailChange,
  onPasswordChange,
  onSignin,
  onPasswordReset,
  location
}) => (
  <AppLayout>
    <Box
      style={{ maxWidth: '48rem', marginLeft: 'auto', marginRight: 'auto' }}
      fill={true}
      pad='xlarge'
    >
      <Heading color='accent-2'>
        welcome to gini
      </Heading>
      {
        error &&
        <Box
          background='status-error'
          align='center'
          direction='row'
          pad='small'
          gap='small'
          round='xsmall'
        >
          <Alert size='small' color='white' />
          <Text size='small' color='white'>{ error }</Text>
        </Box>
      }
      <Box
        gap='medium'
        pad={{ top: 'medium' }}
      >
        <FormField
          label='EMAIL'
        >
          <TextInput
            onChange={evt => onEmailChange(evt.target.value)}
            value={email}
          />
        </FormField>
        <FormField
          label='PASSWORD'
        >
          <TextInput
            type='password'
            onChange={evt => onPasswordChange(evt.target.value)}
            value={password}
          />
        </FormField>
      </Box>
      <Box
        direction='row-responsive'
        align='center'
        pad={{ top: 'medium' }}
        justify='between'
      >
        <Button
          style={{margin: '15px'}}
          onClick={ready && !loading ? () => onSignin(email, password, getParam("page",location), getParam("id",location)) : null}
          label={loading ? 'one sec...' : 'Sign In'}
          color='accent-1'
          primary
        />
        <Button
          style={{margin: '15px'}}
          onClick={() => onPasswordReset()}
          label={loading ? 'one sec...' : 'Forgot Password'}
          color='accent-1'
          primary
        />
      </Box>
      <Link style={{ marginBottom: '1rem' }} to='/signup'>
        <Box justify={'center'}>
          <Text
            style={{ textDecoration: 'underline' }}
            color='accent-2'
            textAlign='center'
            size='medium'
          >
            Don't have an account yet? Create account now!
          </Text>
        </Box>
      </Link>
    </Box>
  </AppLayout>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninPage)
